import _ from 'lodash';
import React from 'react';
import { CardMedia, Container } from '@material-ui/core';
import { useCertificates } from '../../common/Certificates';
import { DEFAULT_LANGUAGE } from '../../properties';
import { LINK_PAGE } from '../../App';

const LOGO_IMAGE_HEIGHT = '96px';

const Partners = ({ selectedLanguage = DEFAULT_LANGUAGE }) => {
    const [data, certificates] = useCertificates(selectedLanguage);
    return (
        <Container maxWidth="lg" className="Home-partners-section" disableGutters>
            {certificates && certificates.map(entry => {
                const title = _.get(entry, 'title');
                const logoSVG = _.get(entry, 'logo_svg');
                const logoPNG = _.get(entry, 'logo_png');
                const externalLink = _.get(entry, 'external_link', LINK_PAGE.ABOUT.path);
                const mediaProps = {
                    className: "logo-element",
                    alt: `${title}-avantre-cert-icon`,
                    title: `${title}-avantre-cert-icon`,
                    height: LOGO_IMAGE_HEIGHT,
                    component: "img",
                }
                return (
                    <div className="partner-container" key={`partner-cert-${title}`}>
                        <a target="_blank" href={externalLink} rel="nofollow">
                            {logoPNG && <CardMedia
                                {...mediaProps}
                                image={logoPNG}
                            />}
                            {logoSVG && <CardMedia
                                {...mediaProps}
                                image={logoSVG}
                            />}
                        </a>
                    </div>
                );
            })}

        </Container>
    );
};

export default Partners;