import _ from 'lodash';
import React from 'react';
import { Container, Paper, Typography, useMediaQuery } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

const TimelineRenderer = ({ steps = [] }) => {
    const screenSizeMatched = useMediaQuery('(min-width:600px)');
    return (
        <Timeline align={screenSizeMatched ? "alternate" : "left"} className="Timeline-component">
            {steps.map(({ title, text }, index) => (
                <TimelineItem key={title+'timelineitem'}>
                    <TimelineSeparator>
                        <TimelineDot variant="outlined" color="secondary">
                            <div className="timeline-number">{index + 1}</div>
                        </TimelineDot>
                        {!_.isEqual(index + 1, steps.length) && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={2}>
                            <Typography variant="h5" color="primary" >
                                {title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {text}
                            </Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
}

const CollaborationProcess = ({ cooperateProcess }) => {
    return (
        <Container maxWidth="lg" className="default-margin" disableGutters>
            <Typography variant="h5" color="primary" align="center" component="h3">
                {cooperateProcess?.title}
            </Typography>
            <div className="Timeline-cards-container">
                <TimelineRenderer steps={cooperateProcess?.steps} />
            </div>
        </Container>
    );
};

export default CollaborationProcess;