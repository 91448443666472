import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Container, Paper, Typography } from '@material-ui/core';
import LabourLeasing from './LabourLeasing';
import OrderSection from '../../common/OrderSection';
import { LANGUAGE_VERSION } from '../../properties';

const ListRenderer = ({ title, text, imgSrc }) => (
    <Paper elevation={2} component="div" className="info-card">
        {imgSrc && <div className="info-card-image">
            <img
                alt={_.camelCase(title)}
                src={imgSrc}
                title={_.camelCase(title)}
                height="64px"
            />
        </div>}
        <div className="info-card-content">
            <Typography variant="h6" color="secondary">
                {title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {text}
            </Typography>
        </div>
    </Paper>
);

const CardRenderer = ({ title, text, imgSrc }) => (
    <Paper elevation={2} component="div" className="info-card">
        {imgSrc && <div className="info-card-image">
            <img
                alt={_.camelCase(title)}
                src={imgSrc}
                title={_.camelCase(title)}
                height="80px"
            />
        </div>}
        <div className="info-card-title">
            <Typography variant="h5" color="secondary">
                {title}
            </Typography>
        </div>
        <div className="info-card-content">
            <Typography variant="body2" color="textSecondary">
                {text}
            </Typography>
        </div>
    </Paper>
);

const Offer = ({ breadcrumbInfo, selectedLanguage }) => {
    const [offerData, setOfferData] = useState({});
    const language = selectedLanguage;

    useEffect(() => {
        fetch(`content/Offer${LANGUAGE_VERSION}.json`)
            .then(response => response.json())
            .then(json => setOfferData(json));
    }, []);

    const languageOfferData = _.get(offerData, language, {});
    const offer = _.get(languageOfferData, 'offer');
    const offerLeasing = _.get(languageOfferData, 'leasing');
    const offerSatisfaction = _.get(languageOfferData, 'customerSatisfaction');
    const offerLink = _.get(languageOfferData, 'offerLink');

    return (
        <div className="Offer">
            <section className="Home-about-us" style={{ backgroundImage: `url('/img/offer.jpg')` }} >
                <Container maxWidth="lg" disableGutters>
                    <Typography variant="h2" gutterBottom component="h1">
                        {languageOfferData?.title}
                    </Typography>
                </Container>
            </section>
            <section>
                <LabourLeasing offerLeasing={offerLeasing} languageOfferData={languageOfferData} />
            </section>
            <section>
                <Container maxWidth="lg" disableGutters>
                    <div className="Info-list-cards-container">
                        {offer && offer.offerList.map(cardProps => <ListRenderer key={cardProps.title + 'listrenderer'} {...cardProps} />)}
                    </div>
                </Container>
            </section>
            <section>
                <Container maxWidth="lg" className="default-margin" disableGutters>
                    <Typography variant="h5" color="primary" align="center">
                        {offerSatisfaction?.title}
                    </Typography>
                    <div className="Info-cards-container">
                        {offerSatisfaction && offerSatisfaction.benefitList.map(cardProps => <CardRenderer key={cardProps.title + 'cardrednderer'} {...cardProps} />)}
                    </div>
                </Container>
            </section>
            {offerLink && <OrderSection title={offerLink?.title} buttonText={offerLink?.contactUs} />}
        </div>
    );
};

export default Offer;