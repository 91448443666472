import _ from 'lodash';
import { Container, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CollaborationProcess from '../offer/CollaborationProcess';
import { LINK_PAGE } from '../../App';
import OrderSection from '../../common/OrderSection';
import { LANGUAGE_VERSION } from '../../properties';

const Collaboration = ({ breadcrumbInfo, selectedLanguage }) => {
    const [offerData, setOfferData] = useState({});
    const language = selectedLanguage;

    useEffect(() => {
        fetch(`content/Offer${LANGUAGE_VERSION}.json`)
            .then(response => response.json())
            .then(json => setOfferData(json));
    }, []);

    const languageOfferData = _.get(offerData, language, {});
    const cooperateProcess = _.get(languageOfferData, 'cooperateProcess');
    const collaborationOffer = _.get(languageOfferData, 'collaborationOffer');

    return (
        <div className="Collaboration">
            {cooperateProcess && <section className="Home-about-us" style={{ backgroundImage: `url('/img/collaboration.jpg')` }} >
                <Container maxWidth="lg" disableGutters>
                    <Typography variant="h2" gutterBottom component="h1">
                        {cooperateProcess?.title}
                    </Typography>
                </Container>
            </section>}
            <section>
                <CollaborationProcess cooperateProcess={cooperateProcess} />
            </section>
            {collaborationOffer && <OrderSection
                {...collaborationOffer}
                link={LINK_PAGE.OFFER.path}
            />}
        </div>
    );
};

export default Collaboration;