
/**
 * Default language for whole page
 * Options available: 'PL', 'DE', 'EN'
 */
export const DEFAULT_LANGUAGE = 'PL';

export const LANGUAGE_VERSION = '_v123';

export const REACT_EMAILJS_APP_SERVICE_ID = 'avantre_group_y2nwto4';
export const REACT_EMAILJS_APP_TEMPLATE_ID = 'template_9u10idg';
export const REACT_EMAILJS_APP_USER_ID = 'user_191GmpGLFdTLWGo5ri2hb';